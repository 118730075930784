import axios from "../../../services/Api";

export default {
  methods: {
    async handleRegister() {
      if (this.acceptAgreement == true) {
        this.loading = true;
        await axios.get("sanctum/csrf-cookie").then(() => {
          axios
            .post("front-catalog/register", this.userForm)
            .then(() => {
              // this.$router.push({ name: "Login" });
              this.loading = false;
              this.successCreated = true;
            })
            .catch(() => {
              this.failCreated = true;
              this.loading = false;
            });
        });
      } else {
        this.acceptPolicy = true;
      }
    },
  },
};
